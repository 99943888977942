<template>
  <div class="homePage sc-width">
    <div class="top">
      <router-link to="/maxsell" class="">
        <div class="back-request">
          <div class="pic"><img src="@/assets/back.png" alt="" /></div>
          <div class="word">Return to List</div>
        </div>
      </router-link>
    </div>

    <div class="main sc-width">
      <div class="title">Sourcing Quotation</div>
      <div class="body">
        <div class="left">
          <div class="sub-title">Inquiry User Info</div>
          <div class="divided"></div>
          <div class="content">
            <Form
              ref="form"
              :model="sampleForm"
              class="register-form"
              :label-width="250"
            >
              <Row :gutter="20">
                <i-col span="12">
                  <FormItem label="Request User" prop="product_name">
                    <Input
                      v-model="sampleForm.user.first_name"
                      readonly="readonly"
                    />
                  </FormItem>
                </i-col>
                <i-col span="12">
                  <FormItem label="Request User Company" prop="product_name">
                    <Input
                      v-model="sampleForm.company.company_name"
                      readonly="readonly"
                    />
                  </FormItem>
                </i-col>
                <i-col span="12">
                  <FormItem label="Request Date" prop="product_name">
                    <Input
                      v-model="sampleForm.created_at"
                      readonly="readonly"
                    />
                  </FormItem>
                </i-col>
              </Row>
            </Form>
          </div>
        </div>
        <div class="right">
          <div class="sub-title">Request Product Info</div>
          <div class="divided"></div>
          <div class="content">
            <Form
              ref="form"
              :model="sampleForm"
              class="register-form"
              :label-width="250"
            >
              <Row :gutter="20">
                <i-col span="16">
                  <FormItem label="Product Name" prop="product_name">
                    <Input v-model="sampleForm.product_name" />
                  </FormItem>
                </i-col>
              </Row>
              <Row :gutter="20">
                <i-col span="8">
                  <FormItem label="Quality Standard" prop="quality_standard">
                    <Input v-model="sampleForm.quality_standard" />
                  </FormItem>
                </i-col>
                <i-col span="8">
                  <FormItem label="Spec" prop="spec">
                    <Input v-model="sampleForm.spec" />
                  </FormItem>
                </i-col>
              </Row>
              <Row :gutter="20">
                <i-col span="12">
                  <FormItem label="COA Attached" prop="coa_attached">
                    <div class="download">
                      <a
                        :href="sampleForm.coa[0].url"
                        v-if="sampleForm.coa.length > 0"
                        target="_blank"
                        ><Icon type="md-download" /> Download File</a
                      >
                    </div>
                  </FormItem>
                </i-col>
              </Row>
              <Row :gutter="20">
                <i-col span="12">
                  <FormItem label="Quantity" prop="quantity">
                    <Input v-model="sampleForm.quantity" />
                  </FormItem>
                </i-col>
              </Row>
              <Row :gutter="20">
                <i-col span="12">
                  <FormItem label="Price Indication" prop="price_indication">
                    <Input v-model="sampleForm.price_indication" />
                  </FormItem>
                </i-col>
              </Row>
              <Row :gutter="20">
                <i-col span="12">
                  <FormItem label="FOB Basis" prop="packaging">
                    <Input v-model="sampleForm.fob_basis" />
                  </FormItem>
                </i-col>
              </Row>
              <Row :gutter="20">
                <i-col span="12">
                  <FormItem label="Package" prop="packaging">
                    <Input v-model="sampleForm.packaging" />
                  </FormItem>
                </i-col>
              </Row>
              <Row :gutter="20">
                <i-col span="12">
                  <FormItem label="Shipment" prop="shipment">
                    <Input v-model="sampleForm.shipment" />
                  </FormItem>
                </i-col>
              </Row>
              <!--              <Row :gutter="20">-->
              <!--                <i-col span="8">-->
              <!--                  <FormItem-->
              <!--                          label="Payment Term"-->

              <!--                          prop="payment_term"-->
              <!--                  >-->
              <!--                    <DatePicker type="date" v-model="sampleForm.payment_term" format="yyyy-MM-dd" placeholder="Please select a date"></DatePicker>-->
              <!--                  </FormItem>-->
              <!--                </i-col>-->
              <!--              </Row>-->
              <Row :gutter="20">
                <i-col span="12">
                  <FormItem
                    label="QC Documents/Facory Certificate"
                    prop="coa_attached"
                  >
                    <div class="download">
                      <a
                        :href="sampleForm.qc[0].url"
                        v-if="sampleForm.qc.length > 0"
                        target="_blank"
                        ><Icon type="md-download" /> Download File</a
                      >
                    </div>
                  </FormItem>
                </i-col>
              </Row>
              <!--              <Row :gutter="20">-->
              <!--                <i-col span="16">-->
              <!--                  <FormItem-->
              <!--                          label="Attached"-->

              <!--                          prop="attached"-->
              <!--                  >-->
              <!--                    <Input v-model="sampleForm.attached" />-->
              <!--                  </FormItem>-->
              <!--                </i-col>-->
              <!--              </Row>-->
              <Row :gutter="20">
                <i-col span="16">
                  <FormItem label="NOTE" prop="validity" :class="inputNote">
                    <Input
                      v-model="sampleForm.note"
                      type="textarea"
                      placeholder=""
                      :rows="4"
                      :class="inputNote"
                      style="width: 100%;height:100px"
                    />
                  </FormItem>
                </i-col>
              </Row>
            </Form>
          </div>
        </div>
      </div>
      <div class="operate-btn" v-if="'exhibitor' !== role">
        <div
          class="accept search-button"
          v-if="company_id !== sampleForm.company_id"
          @click="sendMessage()"
        >
          Send Message
        </div>
      </div>
    </div>
    <div class="main-bottom"></div>
    <PopLogin ref="popLogin"></PopLogin>
  </div>
</template>
<script>
import api from "@/api";
import { mapState } from "vuex";
import PopLogin from "@/components/popLogin";

const { _querySingleSourcing } = api;

export default {
  created() {
    this.getSourcingInfo();
  },
  computed: {
    percent() {
      return this.form2.composition_percent.reduce((all, next) => {
        return all + Number(next.composition);
      }, 0);
    },
    ...mapState("user", ["token", "role", "company_id"])
  },
  filters: {
    shortName(val) {
      if (val) return val.substring(0, 10);
    }
  },
  components: { PopLogin },

  data() {
    return {
      rejectPop: false,
      curItemRejected: null,
      acceptPop: false,
      delPop: false,
      confLoading: false,
      numfLoading: false,
      showInquire: false,
      editLoading: false,
      editNumber: false,
      editNumberPop: false,
      samForm: {
        state: null,
        reject_reason: null,
        tracking_number: null
      },
      isfromReq: this.$route.query.from === "requestlists",
      loading: true,
      createLoading: false,
      required: [
        {
          required: true,
          message: "Please fill"
        }
      ],
      sampleForm: {
        sample_size: null,
        documents: "",
        purpose: null,
        remark: null
      },
      documentList: [],
      sampleInfo: {}
    };
  },

  methods: {
    sendMessage() {
      if ("" === this.role) {
        this.$refs.popLogin.showLogin = true;
      } else {
        this.$router.push("/chat?company=" + this.sampleForm.company_id);
      }
    },
    getSourcingInfo() {
      const id = this.$route.params.id;
      this.createLoading = true;
      _querySingleSourcing({ id })
        .then(({ data }) => {
          console.log(data);
          this.sampleForm = data;
          this.sampleForm.created_at = this.sampleForm.created_at.substring(
            0,
            10
          );
          this.sampleForm.company.company_name =
            this.sampleForm.company.company_name.substring(0, 1) + "*****";
        })
        .catch(({ message }) => {
          this.$Message.error(message);
        })
        .finally(() => {
          this.createLoading = false;
        });
    }
  }
};
</script>

<style lang="less" scoped>
.homePage {
  background: #ffffff;
  margin: 75px auto 0 auto;
}

.sc-width {
}

.top {
  .back-request {
    width: 220px;
    height: 40px;
    background: #ef7f21;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
    margin-top: 50px;
    margin-bottom: 30px;
    position: relative;
    top: 39px;
    .pic {
      width: 8px;
      height: 10px;

      img {
        width: 20px;
        height: 20px;
        position: relative;
        top: 11px;
        left: 22px;
      }
    }

    .word {
      position: relative;
      top: 1px;
      font-size: 20px;

      font-weight: 500;
      color: #ffffff;
      line-height: 18px;
    }
  }
}
.main-bottom {
  height: 50px;
}
.main {
  margin-top: 90px;
  /*margin-bottom: 50px;*/
  padding-bottom: 30px;
  .title {
    height: 90px;
    background: linear-gradient(90deg, #a25d72, #2d3b81);
    opacity: 0.9;
    border-radius: 5px;
    font-size: 28px;

    font-weight: 700;
    color: #ffffff;
    line-height: 18px;
    padding: 35px 0 0 50px;
  }
}

.operate-btn {
  display: flex;
  justify-content: center;
  margin-top: 50px;
  div {
    cursor: pointer;
  }
  .reject {
    width: 180px;
    height: 34px;
    border: 1px solid #ef7f21;
    border-radius: 5px;
    font-size: 18px;

    font-weight: 400;
    color: #ef7f21;
    line-height: 18px;
    text-align: center;
    padding-top: 7px;
    margin-right: 20px;
  }

  .accept {
    width: 180px;
    height: 34px;
    background: linear-gradient(90deg, #f5a210, #ef8126);
    border-radius: 5px;
    font-size: 18px;

    font-weight: 400;
    color: #ffffff;
    line-height: 18px;
    text-align: center;
    padding-top: 7px;
  }
}

.back {
  color: @primary-color;
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 14px 24px;
  border-bottom: 1px solid #ccc;
  font-weight: bold;
  background: #fff;
}

.wide {
  width: 1000px;
  margin: 0 auto;

  .doc {
    font-size: 16px;
    color: #ff6600;
    padding: 2px 6px;
    line-height: 24px;

    a {
      color: #ff6600;
    }
  }

  .main-title {
    text-align: center;
    color: #000;
    margin: 30px 0;
  }

  .divide {
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
    margin-bottom: 20px;
  }

  .item-tips {
    position: absolute;
    right: 0;
    font-size: 12px;

    .info {
      color: #999;
    }

    .link {
      color: @primary-color;
      text-decoration: underline;
    }
  }

  .form-box-group {
    .form-box-title {
      text-align: right;
      vertical-align: middle;
      float: left;
      font-size: 12px;
      color: #515a6e;
      line-height: 1;
      padding: 10px 12px 10px 0;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    .compose {
      display: inline-block;
      width: 100%;
      border: 1px solid #ccc;
      padding: 10px;
      border-radius: 4px;
      margin-bottom: 6px;
    }
  }
}

.remarks {
  border: 1px dotted #dcdee2;
  border-radius: 4px;
  background-color: #f3f3f3;
  padding: 0 8px;
  word-break: break-all;
}

/deep/ .ivu-form-item-content .ivu-input,
/deep/ .ivu-select-default .ivu-select-selected-value {
  font-size: 16px;
  color: #333;
  border: none;
  background-color: #f7963317;
}

/deep/ .ivu-select-selection {
  border: none;

  .ivu-icon {
    display: none;
  }

  &:hover .ivu-icon {
    display: none;
  }
}

/deep/ .longLabel .ivu-form-item-label {
  padding: 3px 6px 3px 0;
}

/deep/ .inpTextarea textarea {
  resize: none;
  width: 680px;
}

.body {
  /*display: flex;*/

  .left {
    margin-right: 50px;

    .sub-title {
      font-size: 24px;

      font-weight: 500;
      color: #323538;
      line-height: 18px;
      margin-top: 40px;
      margin-left: 50px;
    }

    .divided {
      height: 1px;
      background: #999999;
      margin-top: 13px;
      margin-bottom: 30px;
      margin-left: 50px;
    }

    .content {
      .item-c {
        display: flex;
        justify-content: space-around;
        .item {
          display: flex;
          justify-content: center;
          margin-bottom: 20px;

          .label {
            padding-top: 8px;
            padding-right: 10px;
            font-size: 16px;

            font-weight: 400;
            color: #52575c;
            line-height: 18px;
          }

          .value {
            width: 350px;
            height: 35px;
            /*background: #FFF7F1;*/
            border-radius: 5px;
            background-color: #fff7f1;
            padding: 9px 0 0 10px;

            input {
              width: 100%;
              height: 100%;
              outline: none;
              border: none;
              background-color: #fff7f1;
            }
          }
        }
      }
    }
  }

  .right {
    .sub-title {
      font-size: 24px;

      font-weight: 500;
      color: #323538;
      line-height: 18px;
      margin-top: 40px;
      margin-left: 50px;
    }

    .divided {
      /*width: 600px;*/
      height: 1px;
      background: #999999;
      margin-top: 13px;
      margin-bottom: 30px;
      margin-left: 50px;
    }

    .content {
      .item {
        display: flex;
        justify-content: center;
        margin-bottom: 20px;

        .label {
          padding-top: 8px;
          padding-right: 10px;
          font-size: 16px;

          font-weight: 400;
          color: #52575c;
          line-height: 18px;
        }

        .value {
          width: 350px;
          height: 35px;
          background: #fff7f1;
          border-radius: 5px;
          padding: 9px 0 0 10px;

          input {
            width: 100%;
            height: 100%;
            outline: none;
            border: none;
            background-color: #fff7f1;
          }
        }
      }
    }
  }
}
.download {
  a {
    color: #f57c00;
  }
}
</style>
